.control-panel {
    padding-top:1.5rem;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-start: 3;
}
.signees-control-panel {
    padding-top:1.5rem;
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 3;
    grid-row-start: 4;
}


.makeStyles-signeeCard-14 span:first-child  {
    text-align: left;
    margin-left: 1rem;
}

.makeStyles-signeeCard-14 span:nth-child(3) {
    text-align: right;
    margin-right: 1rem;
}

.makeStyles-signeeCard-14:first-child  {
    border-top: unset;
}

