.calendar {
    position: relative;
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-start: 3;
    max-width: 100%;
    display:block;
    overflow-x: scroll;
    border: solid 2px black;
}

.calendar thead tr th {
    position: sticky; top: 0;
    background: #eaeaea;
}
