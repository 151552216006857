
.welcome-container {
    position: relative;
    text-align: center;
}

    .hero-image {
        max-width: 100%;
        width: 100%;
        opacity: 0.95;
        height: 400px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        background-image: 
            linear-gradient(to top, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 30%),
            url(../images/HeroImage.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    .welcome-title {
        position: absolute;
        width: 100%;
        left: 50%;
        bottom: 0px;
        margin-bottom: 28px;
        transform: translate(-50%, 0%);
        color: white;
        font-family: Arial, Helvetica, sans-serif;
        font-size: calc(3vw + 1rem);
        font-weight: 100;
    }
    .welcome-title::after {
        content: "";
        height: 2px;
        background: white;
        position: absolute;
        bottom: 0;
        width: calc(6.2rem + 44vw);
        left: calc(50% - (6.2rem + 44vw) / 2);
    }

.parting-container {
    display: flex;
    flex-direction: row;
}
.location-container {
    flex: 1 1;
    padding-top: 8rem;
    display: flex;
    flex-direction: column;
    margin: 0 10%;
    text-align: center;
    height: 100%;
    align-items: center;
}
    .gm-svpc {
        visibility: hidden;
    }
    .gm-style > button{
        visibility: hidden;
    }
    .gm-style-mtc > div {
        visibility: hidden;
    }
    .location-container div:first-of-type {
        height: 400px;
        width: 100%;
        position: relative;

    }
    .location-title {
        padding-top: 1rem;
        font-family: Helvetica;
        font-weight: bold;
    }

.about-me-container {
    flex: 1 1;
    padding-top: 8rem;
    display: flex;
    flex-direction: column;
    margin: 0 10%;
    text-align: center;
}
    .img-container {
        height: 160px;
        width: 160px;
        margin-left: auto;
        margin-right: auto;
    }
    .img-container img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .about-me-container span:nth-of-type(1) {
        padding-top: 2rem;
        font-family: Helvetica;
        font-weight: bold;
    }
    .about-me-container span:nth-of-type(2) {
        padding-top: 0.2rem;
        font-family: Helvetica;
        font-weight: bold;
        padding-bottom: 1rem;
    }


.book-container {
    padding-top: 4rem;
    flex-direction: column;
    position: relative;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 272px;
    min-height: 200px;
    height: fit-content;
    width: fit-content;
    margin-right: auto;
    margin-left: auto;
}

    
    .book-container a {
        justify-content: center;
        align-items: center;
        display: grid;
        grid-template-columns: 2.0fr 3.4fr 2.45fr;
        grid-template-rows: 1.4fr 7fr 1.1fr;
        max-width: 100%;
        height: auto;
        padding-right: calc(0.5vw + 0.5rem);
        padding-left: calc(0.5vw + 0.5rem);
        margin: 1%;
    }

    .book-container.right__aligned a {
        justify-content: center;
        align-items: center;
        display: grid;
        grid-template-columns: 2.0fr 3.4fr 2.45fr;
        grid-template-rows: 1.4fr 7fr 1.1fr;
        max-width: 100%;
        height: auto;
        padding-left: calc(1vw + 1rem);
        padding-right: 0;
    }
    
    .book-container.left__aligned a {
        justify-content: center;
        align-items: center;
        display: grid;
        grid-template-columns: 2.0fr 3.4fr 2.45fr;
        grid-template-rows: 1.4fr 7fr 1.1fr;
        max-width: 100%;
        height: auto;
        padding-right: calc(1vw + 1rem);
        padding-left: 0;
    }
    
    .book-image {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 4;
        max-width: 100%;
        height: auto;
        max-height: 100%;
        width: 100%;
    }
    .book-today-text-container {
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 3;
        height: 100%;
        max-width: 100%;
        width: 100%;
    }
    .click-here-text{
        grid-column-start: 2;
        grid-column-end: 4;
        grid-row-start: 3;
        grid-row-end: 4;
        height: 100%;
        max-width: 100%;
        width: 100%;
    }
 
    .book-image:hover {
        opacity: 0.8;
    }
    .book-ballet-image:hover {
        opacity: 0.8;
    }
    .book-zumba-image:hover {
        opacity: 0.8;
    }

.class-description {
    font-family: Helvetica;
    padding-top: 2rem;
    height: 100%;
    margin: 0rem 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.class-description span { 
    margin-bottom: 0.4rem;
};

a {
    font-family: arial;
    /* color: rgb(255 255 255); */
    /* color: #8BC34A; */
    text-decoration: none;
}
@media (max-width: 400px) {
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-6.MuiGrid-grid-lg-4 {
        padding-top: 1rem;
    }
    .welcome-title {
        font-size: calc(1.2vw + 1rem);
    }
}
@media (max-width: 370px) {
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-6.MuiGrid-grid-lg-4 {
        padding-top: 0rem;
    }
}



@media  (min-width: 400px) and (max-width: 600px) {
    .welcome-title {
        font-size: calc(2vw + 1rem);
    }
}

@media  (min-width: 320px) and (max-width: 768px){
    .book-container {
        padding-top: 1rem;
    }
    .book-container.right__aligned {
        padding-top: 3rem;
    }
    .about-me-container {
        padding-top: 4rem;
    }
    .location-container {
        padding-top: 4rem;
    }
}

@media (max-width: 1280px) {
    .parting-container {
        flex-direction: column-reverse;
    }
    .book-container a {
        padding-right: 0;
        padding-left: 0;
    }
    .book-container.right__aligned a {
        padding-right: 0;
        padding-left: 0;
    }
    .book-container.left__aligned a {
        padding-right: 0;
        padding-left: 0;
    }
    .right__aligned {
        margin-right: auto;
        margin-left: auto;
    }
    .left__aligned {
        margin-right: auto;
        margin-left: auto;
    }
}
@media (min-width: 1280px) {
    /* .location-container {
        margin: 0 20%;
    } */
    .class-description {
        margin: 0rem 20%;
    }
    .right__aligned {
        margin-right: unset;
        margin-left: auto;
    }
    .left__aligned {
        margin-left: unset;
        margin-right: auto;
    }
}
@media (min-width: 320px) and (max-width: 1280px) {
    .book-container a {
        margin: 0 5%;
    }
    .location-container {
        margin: 0 5%;
    }
    .class-description {
        margin: 0 5%;
    }
}