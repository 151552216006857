table {
    border-collapse: collapse;
    table-layout: fixed;
}

.calendar-cell {
    min-width: 100px;
    min-height: 54px;
    padding-top: 10px;
    padding-bottom: 5px;
    border: 1px solid gray; 
    text-align: center;
}

.start {
    border-bottom:  0px solid gray; 
}

.middle {
    border-top:  0px solid gray; 
    border-bottom:  0px solid gray; 
}

.end {
    border-top:  0px solid gray; 
}

.calendar-cell.active {
    background-color: green;
}
.calendar-cell.activate {
    background-color: blue;
}
.calendar-cell.deactivate {
    background-color: yellow;
}
.calendar-cell.disabled {
    background-color: gray;
}