.footer-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    max-width: 100vw;
    width: 100%;
    bottom: 0px;
    min-height: 7rem;
    background-color: #e4e4e4;
}

    .footer-container .contact-container  {
        margin-left: 1rem;
        margin-top: 1rem;
        width: fit-content;
        margin-right: auto;
    }
    .footer-container .contact-container a:last-of-type {
        text-decoration: underline;
        color: rgb(112, 112, 112);
    }

    .footer-container .legal-container  {
        margin-right: 1rem;
        margin-top: 1rem;
        text-align: right;
        margin-right: 1rem;
        width: fit-content;
        margin-left: auto;
    }
        .footer-container .legal-container a {
            text-decoration: underline;
            color: #707070;
        }

    .footer-container .social-container  {
        text-align: center;
        padding-bottom: 1rem;
        padding-top: 1rem;
    }
        .footer-container .social-container img  {
            max-width: 28px;
            max-height: 28px;
            object-fit: contain;
        }
        .footer-container .social-container a  {
            text-decoration: none;
        }
        .footer-container .social-container > *:first-child  {
            margin-right: 14px;
        }
