.booking-day-container {
    display: grid;
    z-index: 1000;
    position: absolute;
    left: 0px;
    top: 0px;
    overflow: visible;
    width: 100%;
    height: 100%;
    background-color: rgba(206, 206, 206, 0.7);
    animation: background-change 0.2s ease-in-out  1;
    grid-template-rows: 2fr 4fr 2fr;
    grid-template-columns: 3fr 2.5fr 3fr;
    grid-template-areas: 
            ". . ."
            ". day-card ."
            ". . .";
 }

.booking-day-card {
    height: fit-content;
    position: relative;
    grid-area: day-card;
    background-color: white;
    box-shadow: 0px -1px 19px -7px rgba(0,0,0,0.63);
}
.booking-day-card img {
    position: absolute;
    right: -20px;
    top: -20px;
}

.booking-day-card .vertical-container {
    display:flex;
    flex-direction: column;
    height: fit-content;
    padding: 1rem;
}

.booking-day-compulsory-text {
    color: rgba(51, 142, 55, 1);
}
.booking-day-compulsory-text {
    color: rgba(51, 142, 55, 1);
}
    .booking-day-compulsory-text.validation-error {
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .booking-day-card .vertical-container .booking-day-header {
        display:flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
    }

        .booking-day-card .vertical-container .booking-day-header .header-title {
            padding-bottom: 1.5rem;
            font-size: 1.5rem;
            font-weight: 100;
            font-family: helvetica;
        }
        .booking-day-card .vertical-container .booking-day-header .header-date {
            color: rgba(132, 132, 132, 1);
        }

    .booking-day-card .vertical-container .booking-day-info {
        padding: 1rem;
        padding-top: 0.6rem;
        padding-bottom: 0;
        display:flex;
        flex-direction: column;
        align-items: left;
        flex: 1;
    }


        .booking-day-card .vertical-container .booking-day-info .option-text {
            margin-top: 0.6rem;
        }
    
    .booking-day-card .vertical-container .booking-day-options {
        display:flex;
        flex-direction: row;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        flex-wrap: wrap;
        overflow-wrap: anywhere;
        flex: 1;
    }
        .booking-day-card .vertical-container .booking-day-options .booking-day-option {
            height: 2.5rem;
            margin-left: 1rem;
            margin-top: 0.5rem;
            width: 9rem;
            padding: 0.5rem;
            background-color: white;
            outline-width: 0;
            border: 1px solid rgba(132, 132, 132, 0.4);
            transition: background-color 0.3s ease-in-out;
        }
        .booking-day-card .vertical-container .booking-day-options .booking-day-option:only-child {
            margin-left: auto;
            margin-right: auto;
        }
        .booking-day-card .vertical-container .booking-day-options .booking-day-option.active {
            border: 1px solid rgba(51, 142, 55, 0.4);
        }
        .booking-day-card .vertical-container .booking-day-options .booking-day-option:hover {
            transition: background-color 0.3s ease-in-out;
            background-color: rgba(132, 132, 132, 0.1);
        }

    
    .booking-day-card .vertical-container .booking-day-form {
        display:flex;
        flex-direction: column;
        align-items: left;
        padding: 1rem;
        flex: 4 4;
    }
        .booking-day-card .vertical-container .booking-day-form input {
            margin: 0.3rem;
            padding: 0.5rem;
            margin-left: 1rem;
            border: 1px solid rgba(132, 132, 132, 0.15);
            flex: 1 1;
        }
        .booking-day-card .vertical-container .booking-day-form span {
            flex: 1 1;
        }
        .booking-day-card .vertical-container .booking-day-form input:focus{
            outline-width: 0;
        }
        .booking-day-card .vertical-container .booking-day-form .inform-text {
            justify-content: center;
            font-size: 0.7rem;
            color: rgba(159, 159, 159, 1);
            flex: 1 1;
        }

    .booking-day-card .vertical-container .booking-day-form-submit {
        display:flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem;
        flex: 2 2;
    }
        .booking-day-card .vertical-container .booking-day-form-submit button {
            outline-width: 0;
            padding: 0.5rem 1rem;
            background-color: white;
            border: grey solid 0px;
            box-shadow: 0px 3px 5px rgba(132, 132, 132, 0.4);
            transition: box-shadow 0.3s ease-in-out;
        }
            .booking-day-card .vertical-container .booking-day-form-submit button:hover {
                transition: box-shadow 0.3s ease-in-out;
                box-shadow: 0px 5px 10px rgba(132, 132, 132, 0.6);
                outline-width: 0;
            }



            @media (max-width: 450px) {
                .booking-day-card .vertical-container .booking-day-options .booking-day-option {
                    margin-left: auto;  
                    margin-right: auto;
                }
            }
            
            @media (min-width: 450px) {
                .booking-day-container {
                    grid-template-columns: 1fr 10fr 1fr;
                }
            
            }
              
              /* Small devices (portrait tablets and large phones, 600px and up) */
            @media (min-width: 600px) {
                .booking-day-container {
                    grid-template-columns: 2fr 8fr 2fr;
                }
            }
              
              /* Medium devices (landscape tablets, 768px and up) */
            @media  (min-width: 768px) {
                .booking-day-container {
                    grid-template-columns: 2fr 6fr 2fr;
                }
            }
              
              /* Large devices (laptops/desktops, 992px and up) */
              @media (min-width: 992px) {
                .booking-day-container {
                    grid-template-columns: 2fr 4fr 2fr;
                }
              }
              
              /* Extra large devices (large laptops and desktops, 1200px and up) */
              @media (min-width: 1200px) {
                .booking-day-container {
                grid-template-columns: 3fr 2.5fr 3fr;
                }
              }
            

@keyframes background-change {
    from {background-color: rgba(206, 206, 206, 0.0);}
    to {background-color: rgba(206, 206, 206, 0.7);}
  }