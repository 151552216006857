.admin-page-container {
  display: grid;
  height: 100vh;
  grid-template-columns: 2fr 8fr 1fr 40fr 12fr;
  grid-template-rows: 3fr 2fr 20fr  4fr;
}

.calendar-options {
  display: flex;
  align-items: baseline;
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 2;
  grid-row-end: 3;
}

.calendar-options .option{
  padding-left: 1.5rem;
  font-size: 2rem;
}

.calendar-options .option.selected{
  font-weight: 700;
}