.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-12.MuiGrid-grid-lg-12 {
    position: relative;
}

@media (max-width: 450px) {
    .booking-day-card .vertical-container .booking-day-options .booking-day-option {
        margin-left: auto;  
        margin-right: auto;
    }
}

@media (min-width: 450px) {
    .booking-day-container {
        grid-template-columns: 1fr 10fr 1fr;
    }

}
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 660px) { 
     .booking-text {
        font-size: 0.9375rem;
    }
}
  
  /* Medium devices (landscape tablets, 768px and up) */
@media  (min-width: 768px) {
    .booking-day-container {
        grid-template-columns: 2fr 6fr 2fr;
    }
}
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media (min-width: 992px) {
    .booking-day-container {
        grid-template-columns: 2fr 4fr 2fr;
    }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media (min-width: 1200px) {
    .booking-day-container {
    grid-template-columns: 3fr 2.5fr 3fr;
    }
  }


.booking-text {
    display: flex;
    flex-direction: column;
    padding-top: 1.625rem;
    padding-bottom: 1.875rem;
    align-items: center;
}

.booking-text span:first-child {
    margin-bottom: 0.3rem;
    font-weight: bold;
    font-size: 1.25rem;
}

.download-button {
    margin-top: 3rem;
    text-decoration: none;
    color: black;
    width: fit-content;
    outline-width: 0;
    padding: 0.5rem 1rem;
    background-color: white;
    border: grey solid 0px;
    box-shadow: 0px 3px 5px rgba(132, 132, 132, 0.4);
    transition: box-shadow 0.3s ease-in-out;
}

.download-button:hover {
    transition: box-shadow 0.3s ease-in-out;
    box-shadow: 0px 5px 10px rgba(132, 132, 132, 0.6);
    outline-width: 0;
}

.kids-ballet-title {
    width: 100%;
    left: 50%;
    bottom: 0px;
    transform: translate(-50%, 0%);
    margin-bottom: 28px;
    position: absolute;
    text-align: center;
    font-family: Helvetica;
    font-size: calc(2rem + 1vw);
    color: white;
}

.kids-ballet-title::after {
    content: "";
    height: 2px;
    background: white;
    position: absolute;
    bottom: 0;
    width: calc(9.8rem + 1.5vw);
    left: calc(50% - (9.8rem + 1.5vw) / 2);
}

.hero-image-kids-ballet {
    background-image: 
            linear-gradient(to top, rgba(0,0,0,0.9) 0%, rgba(255,255,255,0) 26%),
            url(../images/KidsBalletHero.jpg);
    max-width: 100%;
    width: 100%;
    opacity: 0.95;
    height: 400px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

