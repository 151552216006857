.booking-week-list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
    .booking-week-list-container .calendar-title {
        padding-bottom: 1rem;
        font-size: 1.5625rem;
    }
    .booking-week-list-card-container {
        position: relative;
        display: flex;
        flex-direction: column;
        font-family: Helvetica;
        width: 100%;
    }
        .booking-week-list-card-container .booking-week-list-card {
            width: 100%;
            height: 2rem;
            background-color: white;
            padding: 1.5rem 0rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 1rem;
        }
        .booking-week-list-card-container .booking-week-list-card.even{
            background-color: rgba(242, 247, 240, 1)
        }
            .booking-week-list-card-container .booking-week-list-card .card-date{
                width: 33.3%;
                text-align: left;
                padding: 1rem;
            }
            .booking-week-list-card-container .booking-week-list-card .available-class-text{
                width: 33.3%;
                text-align: center;
                padding: 1rem;
            }
            .booking-week-list-card-container .booking-week-list-card .open-button {
                width: 33.3%;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            @media (max-width: 600px) {
                .booking-week-list-card-container .booking-week-list-card .card-date {
                    font-size: 0.9rem;
                    padding: 0.8rem;
                }
                .booking-week-list-card-container .booking-week-list-card .available-class-text {
                    font-size: 0.9rem;
                    padding: 0.8rem;
                }
                .booking-week-list-card-container .booking-week-list-card .open-button {
                    font-size: 0.9rem;
                }
            
            }
            @media (min-width: 768px) {
                .booking-week-list-card-container .booking-week-list-card .card-date {
                    font-size: 1rem;
                    padding: 1rem;
                }
                .booking-week-list-card-container .booking-week-list-card .available-class-text {
                    padding: 1rem;
                    font-size: 1rem;
                }
                .booking-week-list-card-container .booking-week-list-card .open-button {
                    font-size: 1rem;
                }
            }
            .booking-week-list-card-container .booking-week-list-card .open-button button {
                outline-width: 0;
                padding: 0.5rem 1rem;
                background-color: white;
                border: grey solid 0px;
                box-shadow: 0px 3px 5px rgba(132, 132, 132, 0.4);
                transition: box-shadow 0.3s ease-in-out;
            }
            .booking-week-list-card-container .booking-week-list-card .open-button button:hover {
                transition: box-shadow 0.3s ease-in-out;
                box-shadow: 0px 5px 10px rgba(132, 132, 132, 0.6);
                outline-width: 0;
            }