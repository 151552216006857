.loader-background {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background: #ffffffd1;
    margin-top: auto;
    margin-bottom: auto;
}
.loader-background img {
    width: 6rem;
}
