.header-container {
    z-index: 1000;
    max-width: 100%;
    width: 100%;
    background-color: white;

    display: flex;
    max-height: 4rem;
    height: 4rem;
    align-items: center;
    position: fixed;
    top: 0;
}
.header-title {
    width: 7rem;
    height: 92%;
    margin-left: calc(0.5rem + 1vw);
}
.header-title a {
    text-decoration: none;
    color: rgb(47 47 47);
}

.header-title a img {
    height: 100%;
}

.burger-container {
    background-color: white;
    display: flex;
    width: 100%;
    position: fixed;
    z-index: 1000;
    height: 4rem;
    text-align: end;
    right: 0px;
}
    .burger-container img {
        flex: 1 1;
        position: relative;
        right: 1.25rem;
        top: 1.25rem;
        height: 1.5rem;
    }
    .burger-container .header-title  {
        flex: 5 5;
        top: unset;
        text-align: left;
    }
    .burger-container .header-title img {
        right: unset;
        top: unset;
    }

    .link-container {
        width: 100%;
        position: absolute;
        top: calc(4rem + 1px);
        height: 0px;
        transition: height 0.1s;
    }
    .link-container li {
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
        padding-right: 0rem;
        visibility: hidden;
        opacity: 0;
    }

    .link-container__open {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        position: absolute;
        top: calc(4rem);
        background-color: white;
        border-top: solid 1px #e2e2e2;
        border-bottom: solid 1px #e2e2e2;
        height: 8.5rem;
        transition: height 0.1s;
    }
    .link-container__open::before {
        background: linear-gradient(45deg,rgba(255,255,255,1) 50%,rgba(255,255,255,0) 51%,rgba(255,255,255,0) 100%);
        box-shadow: -1px 1px 0px 0 rgba(178,178,178,.4);
        content: "";
        height: 26px;
        right: 16px;
        position: absolute;
        top: 0;
        transform: translate(-50%,-47%) rotate(135deg);
        width: 26px;
    }
    .link-container__open li {
        transition: opacity 0.2s;
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
        padding-right: 0rem;
        opacity: 10;
    }
    .link-container__open li:last-child {
        padding-bottom: 0.8rem;
    }

    .link-container__open li a::after {
        width: 0%;
    }
    

ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    margin-right: calc(1rem + 0.5vw);
    margin-left: auto;
}
    li {
        display: inline;
        padding-right: 1rem;
    }
        li a {
            position: relative;
            font-family: Helvetica;
            font-size: 1.5rem;
            font-weight: 100;
            color: rgb(0 0 0);
            text-decoration: none;
        }


li a::after {
    content: "";
    height: 2px;
    position: absolute;
    background: black;
    bottom: 0;
    left: 0;
    width: 1rem;
    transition: width 0.5s;
}

li a:hover:after {
    content: "";
    height: 2px;
    position: absolute;
    background: black;
    bottom: 0;
    left: 0;
    width: 100%;
}
li a b {
    /* background-size: 50%;
    background-image: linear-gradient(to right, rgb(47 47 47) 20%, white 20%);
    background-position: 0 1.23rem;
    background-size: 2% 8%;
    width: 50%; */
    border-bottom: 2px solid black;
    padding: 0 0 4px;
}

.underlined {
    background-size: 50%;
    background-image: linear-gradient(to right, rgb(47 47 47) 20%, white 20%);
    background-position: 0 1.23rem;
    background-size: 2% 8%;
    width: 50%;
}
