.booking-text {
    display: flex;
    flex-direction: column;
    padding-top: 1.625rem;
    padding-bottom: 1.875rem;
    text-align: center;
}

.booking-text span:first-child {
    margin-bottom: 0.3rem;
}

.zumba-title {
    width: 100%;
    left: 50%;
    transform: translate(-50%, -145%);
    position: absolute;
    text-align: center;
    font-family: Helvetica;
    font-size: calc(2rem + 1vw);
    color: white;
}

.zumba-title::after {
    content: "";
    height: 2px;
    background: white;
    position: absolute;
    bottom: 0;
    width: calc(6.2rem + 1.1vw);
    left: calc(50% - (6.2rem + 1.1vw) / 2);
}

.hero-image-zumba {
    background-image: 
            linear-gradient(to top, rgba(0,0,0,0.9) 0%, rgba(255,255,255,0) 26%),
            url(../images/ZumbaHero.jpg);
    max-width: 100%;
    width: 100%;
    opacity: 0.95;
    height: 400px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

